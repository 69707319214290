import './assets/scss/app.scss'
import './assets/css/bootstrap.min.css'
import 'vue3-toastify/dist/index.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { Plugin } from 'vue-responsive-video-background-player'
import VueNavigationBar from 'vue-navigation-bar';
import 'vue-navigation-bar/dist/vue-navigation-bar.css';

import es from "./lang/es.json"
import en from "./lang/en.json"

import {createI18n} from "vue-i18n";

const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: "en",
    messages: { es, en },
    legacy: false
})


let app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(Plugin)
app.component('vue-navigation-bar', VueNavigationBar)





app.mount('#app')
