<template>
  <section class="no-top no-bottom">
    <video-background
        class="video-container"
        poster="/test/f.png"
        :ksrc="require(`@/assets/videos/video${Math.floor(Math.random() * 3) + 1}.mp4`)"
        src="/test/a.png"
        overlay="linear-gradient(to bottom, rgba(0, 0, 0, 0.68) 20%, rgba(0, 0, 0, 0.88) 75%, rgba(0, 0, 0, 0.68))"
    >
      <div class="d-flex justify-content-center align-items-center px-2 h-100">
        <div class="container z1000">
          <div class="row align-items-center text-center">
            <div class="spacer-double"></div>
            <div class="spacer-double"></div>

            <div class="col-12">
              <h1>{{ $t('welcomeMessage') }}</h1>
            </div>

            <div class="col-12">
              <p class="lead mb1">{{ $t('description') }}</p>
              <a class="btn-main cursor-pointer" @click="$router.push({ name: 'catalog' })">{{ $t('viewCatalog') }}</a>
            </div>
          </div>
        </div>
      </div>
    </video-background>
  </section>




  <Categories :showBtn="true" :showTitle="true"/>

  <banner-image-texto/>


  <best-seller prod="12"/>





</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Categories from "@/components/Categories.vue";
import BestSeller from "@/components/BestSeller.vue";
import BannerImageTexto from "@/components/BannerImageTexto.vue";
import Contact from "@/components/Contact.vue";
import FooterApp from "@/components/FooterApp.vue";


export default {
  name: 'HomeView',
  components: {
    FooterApp,
    Contact,
    BannerImageTexto,
    BestSeller,
    Categories,
    HelloWorld
  }
}
</script>

<style  >
.video-container{
  min-height: 80vh;
  padding: 0!important;
}
</style>



