export default {
    URL_API: 'https://rockwell.com.mx/backend/api/',
    URL_AUTH: 'https://rockwell.com.mx/backend/oauth/',
    STATES: [
        {
            "clave": "1",
            "nombre": "Aguascalientes"
        },
        {
            "clave": "2",
            "nombre": "Baja California"
        },
        {
            "clave": "3",
            "nombre": "Baja California Sur"
        },
        {
            "clave": "4",
            "nombre": "Campeche"
        },
        {
            "clave": "5",
            "nombre": "Coahuila de Zaragoza"
        },
        {
            "clave": "6",
            "nombre": "Colima"
        },
        {
            "clave": "7",
            "nombre": "Chiapas"
        },
        {
            "clave": "8",
            "nombre": "Chihuahua"
        },
        {
            "clave": "9",
            "nombre": "Distrito Federal"
        },
        {
            "clave": "10",
            "nombre": "Durango"
        },
        {
            "clave": "11",
            "nombre": "Guanajuato"
        },
        {
            "clave": "12",
            "nombre": "Guerrero"
        },
        {
            "clave": "13",
            "nombre": "Hidalgo"
        },
        {
            "clave": "14",
            "nombre": "Jalisco"
        },
        {
            "clave": "15",
            "nombre": "México"
        },
        {
            "clave": "16",
            "nombre": "Michoacán de Ocampo"
        },
        {
            "clave": "17",
            "nombre": "Morelos"
        },
        {
            "clave": "18",
            "nombre": "Nayarit"
        },
        {
            "clave": "19",
            "nombre": "Nuevo León"
        },
        {
            "clave": "20",
            "nombre": "Oaxaca"
        },
        {
            "clave": "21",
            "nombre": "Puebla"
        },
        {
            "clave": "22",
            "nombre": "Querétaro"
        },
        {
            "clave": "23",
            "nombre": "Quintana Roo"
        },
        {
            "clave": "24",
            "nombre": "San Luis Potosí"
        },
        {
            "clave": "25",
            "nombre": "Sinaloa"
        },
        {
            "clave": "26",
            "nombre": "Sonora"
        },
        {
            "clave": "27",
            "nombre": "Tabasco"
        },
        {
            "clave": "28",
            "nombre": "Tamaulipas"
        },
        {
            "clave": "29",
            "nombre": "Tlaxcala"
        },
        {
            "clave": "30",
            "nombre": "Veracruz de Ignacio de la Llave"
        },
        {
            "clave": "31",
            "nombre": "Yucatán"
        },
        {
            "clave": "32",
            "nombre": "Zacatecas"
        }
    ],
    SALE_STATUS: {
        'pending' : 'Pendiente',
        'confirm' : 'Pago confirmado',
        'dispatched' : 'Enviado',
        'closed' : 'Entregada',
        'canceled' : 'Cancelada',
    }
}
