<script setup>
import brandImage from '@/assets/logo_white.svg'
import Header from "@/components/Header.vue";
import Contact from "@/components/Contact.vue";
import FooterApp from "@/components/FooterApp.vue";
import {ref} from "vue";
const showMobileMenu = ref(false);
const showMenu = ()=>{
  showMobileMenu.value = !showMobileMenu.value
}


</script>

<template>



  <div id="wrapper" >


    <!-- header begin -->
    <Header/>
    <!-- header close -->

    <!-- content begin -->
    <div class="no-bottom no-top" id="content">
      <div id="top"></div>

      <slot/>


      <contact/>

      <footer-app/>

    </div>


  </div>

</template>

<style scoped lang="scss">

</style>