import { createStore } from 'vuex'
import users from './modules/user'
export default createStore({
  state: {
    isLogin: false
  },
  getters: {
  },
  mutations: {
    setLogin(state, payload ){
      state.isLogin = payload
    }
  },
  actions: {
  },
  modules: {
    user: users
  }
})
