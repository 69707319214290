<template>
  <Layout>
    <router-view/>
  </Layout>
</template>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script  setup>
import Layout from "@/components/Layout.vue";
import {onMounted} from "vue";
import {useStore} from "vuex";


const store = useStore()

onMounted(()=>{
  store.dispatch("user/getCategories")
  let status = localStorage.getItem('login')
  if (status){
    store.dispatch("user/getUsers", {}).catch(err=>{status=false})
  }
  store.commit('setLogin', status)
})

</script>