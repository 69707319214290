<script setup>

</script>

<template>

  <section id="subheader" class="jarallax">
    <img src="@/assets/images/bg_7.jpg" class="bg_image_title" alt="">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 text-center" style="z-index: 20">
          <h1>{{ $t('aboutUs') }}</h1>
          <div class="de-separator"></div>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>

  <section aria-label="section" class="no-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="lead big">
            {{ $t('proudStatement') }}
          </p>
          <p class="lead big">
            {{ $t('rockwellExcellence') }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="no-top">
    <div class="de-gradient-edge-top"></div>
    <img src="@/assets/images/bg_1.png" class="bg_image_title" alt="">
    <div class="container relative z1000">
      <div class="row gx-5">
        <div class="col-lg-6">
          <div class="d-sch-table">
            <h2 class="text-center">{{ $t('ourMission') }}</h2>
            <div class="de-separator"></div>
            <p class="text-white fz19">
              {{ $t('missionDescription') }}
            </p>
            <div class="d-deco"></div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="d-sch-table">
            <h2 class="text-center">{{ $t('ourVision') }}</h2>
            <div class="de-separator"></div>
            <p class="text-white fz19">
              {{ $t('visionDescription') }}
            </p>
            <div class="d-deco"></div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-6 offset-lg-3">
          <div class="d-sch-table">
            <h2 class="text-center">{{ $t('ourValues') }}</h2>
            <div class="de-separator"></div>
            <ul class="valores">
              <li>
                <span>{{ $t('commitment') }}</span> <br> {{ $t('commitmentDescription') }}
              </li>
              <li>
                <span>{{ $t('passion') }}</span> <br> {{ $t('passionDescription') }}
              </li>
              <li>
                <span>{{ $t('loyalty') }}</span> <br> {{ $t('loyaltyDescription') }}
              </li>
            </ul>
            <div class="d-deco"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>




  <section aria-label="section" v-if="false" class="no-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="">Nuestro Equipo</h2>
          <div class="de-separator"></div>
        </div>
        <div class="col-lg-3 offset-lg-3 text-center" >
          <div class="de-box-a">
            <div class="d-image">
              <img src="@/assets/images/users/samuel.jpeg" alt="">
            </div>
            <div class="d-deco-1"></div>
            <div class="d-deco-2"></div>
            <div class="d-social">
              <a href="#"><i class="fa fa-facebook"></i></a>
              <a href="#"><i class="fa fa-twitter"></i></a>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
          </div>
          <div class="spacer-20"></div>
          <h4>Samuel Olmedo</h4>
        </div>

        <div class="col-lg-3  text-center">
          <div class="de-box-a">
            <div class="d-image">
              <img src="@/assets/images/users/brayan.jpg" alt="">
            </div>
            <div class="d-deco-1"></div>
            <div class="d-deco-2"></div>
            <div class="d-social">
              <a href="#"><i class="fa fa-facebook"></i></a>
              <a href="#"><i class="fa fa-twitter"></i></a>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
          </div>
          <div class="spacer-20"></div>
          <h4>Brayan Olmedo</h4>
        </div>
      </div>
    </div>
  </section>





</template>

<style scoped lang="scss">


.fz19{
  font-size: 19px;
}

.bg_image_title{
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100vw;
  top: 0;
}

.valores{
  list-style: none;
  li{
    color: white;
    font-size: 19px;
    margin-top: 15px;
    span{
      font-size: 25px;
      font-weight: bold;
    }
  }
}

</style>
