import axios from "../../api/client";
import axiosInstance from "../../api/client";


// initial state
export default  {
    namespaced: true,
    state: () => ({
        user: {},
        cart: [],
        categories: [],
        buy_now: null
    }),
    mutations: {
        setUser (state, payload) {
            state.user = payload
        },
        setCart (state, payload) {
            state.cart = payload
        },
        addCart (state, payload) {
            state.cart.push(payload)
        },
        buyNow (state, payload) {
            state.buy_now = payload
        },
        setCategories (state, payload) {
            state.categories = payload
        },
    },
    actions:{
        getUsers({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/user').then(response=>{
                        commit('setUser',response.data);
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        storeSale({commit}, query){
            return new Promise((resolve, reject) => {
                axios.post('/sales',{
                   ...query
                }).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getSales({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/my_sales').then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getCategories({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/categories/list').then(response=>{
                        commit("setCategories", response.data.data)
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
    },
    getters: {

    }
}
