<template>
    <svg :style='svgStyle'>
        <text x='50%' y='50%' text-anchor='middle' dominant-baseline='central' :style='textStyle'>
            {{ avatarLetter }}
        </text>
    </svg>
</template>
<script>
    export default {
        name:'VueLetterAvatar',
        props: {
            size: {
                type: String,
                default: '50'
            },
            rounded: {
                type: String,
                default: '0'
            },
            name: {
                type: String,
                required: true
            }
        },
        computed: {
            avatarLetter() {
                if (this.name){
                  let name = this.name.replace(/\s+/g, ' '); // remove multiple spaces
                  let arr = name.split(' ');
                  return arr.length > 1 ? (arr[0][0].toUpperCase()+arr[1][0].toUpperCase()) : arr[0][0].toUpperCase();
                }
                return  ""
            },
            svgStyle() {
                // use hsl to define the background color.
                const letterIndex = this.avatarLetter.charCodeAt() - 64;
                const hue = (360 / 26) * letterIndex;
                const backgroundColor = `hsl(${hue}, 68%, 48%)`;
                return {
                    width: `${this.size}px`,
                    height: `${this.size}px`,
                    borderRadius: this.rounded ?  this.rounded  : '0',
                    background: "rgb(237 156 13)"
                };
            },
            textStyle() {
                return {
                    fill: 'rgba(255, 255, 255, .9)',
                    fontFamily: "'Lucida Console', Monaco, monospace",
                    border: "1px solid white",
                    fontSize: `${this.size * 0.5}px`
                };
            }
        }
    };
</script>
