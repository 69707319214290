<script setup>

</script>

<template>
  <footer>
    <div class="container">
      <div class="row g-4">

        <div class="col-lg-4 text-lg-start text-center">
          <div class="social-icons">
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100057499897900"><i class="fa fa-facebook fa-lg"></i></a>
            <a target="_blank" href="https://www.instagram.com/mr_rockwell_/?hl=es-la"><i class="fa fa-twitter fa-lg"></i></a>
          </div>
        </div>
        <div class="col-lg-4 text-lg-center text-center">
          <img src="@/assets/images/logo_white.svg" class="" style="width: 120px" alt="">
        </div>
        <div class="col-lg-4 text-lg-end text-center text-white">
          Copyright 2023 - Rockwell
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">

</style>