<script setup>

</script>

<template>
  <section class="banner_uno">
    <div class="de-gradient-edge-top"></div>
    <div class="container position-relative mt-1">
      <div class="row">
        <div class="col-lg-4 col-md-4 text-center">
          <i class="fa fa-map-marker de-icon gradient de-hover-scale text-light mb20"></i>
          <p class="lead no-bottom">{{ $t('location') }}</p>
          <h4 class="s2">{{ $t('cityLocation') }}</h4>
        </div>
        <div class="col-lg-4 col-md-4 text-center">
          <i class="fa fa-phone de-icon gradient de-hover-scale text-light mb20"></i>
          <p class="lead no-bottom">{{ $t('phoneNumber') }}</p>
          <h4 class="s2">contacto@rockwell.com.mx</h4>
        </div>
        <div class="col-lg-4 col-md-4 text-center">
          <i class="fa fa-envelope-o de-icon gradient de-hover-scale text-light mb20"></i>
          <p class="lead no-bottom">{{ $t('emailAddress') }}</p>
          <h4 class="s2">+52 55-4768-9464</h4>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>

</template>

<style lang="scss" scoped>
.img-fluid{
  max-width: 400px;
  max-height: 400px;
}
.banner_uno{
  background: url("@/assets/images/bg_5.jpg") ;
  position: relative;
  padding: 50px 0;
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
    left: 0;
    top: 0;
  }
}
</style>
