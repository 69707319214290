<script setup>

</script>

<template>
  <section class="banner_uno">
    <div class="de-gradient-edge-top"></div>
    <div class="container relative z1000">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <img src="@/assets/images/bg_samuel.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6">
          <h2><span class="id-color">{{ $t('brandName') }}</span></h2>
          <p class="text-white">{{ $t('generationDescription') }}</p>
          <p class="text-white">{{ $t('experienceDescription') }}</p>
          <p class="text-white">{{ $t('prideDescription') }}</p>
          <a @click="$router.push({ name: 'about' })" class="btn-main">{{ $t('aboutUs') }}</a>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>
</template>

<style lang="scss" scoped>
.img-fluid{
  max-width: 400px;
  max-height: 400px;
}
.banner_uno{
  background-image: url("@/assets/images/bg_01.jpeg") ;
  background-size: cover;
  background-position-y: 50%;
  position: relative;
  padding: 50px 0;
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    left: 0;
    top: 0;
  }
}
</style>
